html {
    webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}
body {
    backgroundcolor: #f4f6f8;
    height: 100%;
    width: 100%;
}
a {
    textdecoration: "none";
}
#root {
    height: 100%;
    width: 100%;
}
